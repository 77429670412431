import * as React from "react"

const ClickForMore = () => {
  return (
    <span style={{textDecoration: "underline", color: "red"}}>
      点击阅读更多...
    </span>
  )
}

export default ClickForMore
